/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

/* In styles.css */
@import "~primeng/resources/themes/lara-light-teal/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeicons/primeicons.css";
@import "~primeflex/primeflex.css";


.loading-indicator {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading-indicator:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.53);
  }

  @media print {
    /* Hide the layout elements */
    header, footer, nav, aside, .non-printable {
      display: none !important;
    }
  
    /* Ensure the print content is full width */
    .print-container {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
  